import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ApprovalHistoryComponent } from './component/approval-history/approval-history.component';
import { ApprovalsComponent } from './component/approvals/approvals.component';
import { LoginComponent } from './component/auth/login/login.component';
import { ConfigurationSettingsComponent } from './component/configuration-settings/configuration-settings.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { HomeComponent } from './component/home/home.component';
import { ManageConfigurationSettingsComponent } from './component/manage-configuration-settings/manage-configuration-settings.component';
import { ManageTransactionsComponent } from './component/manage-transactions/manage-transactions.component';
import { MenuComponent } from './component/menu/menu.component';
import { TransactionDetailComponent } from './component/transaction-detail/transaction-detail.component';
import { TransactionsComponent } from './component/transactions/transactions.component';
import { AuthGuard } from './core/auth-guard';
import { ScheduleComponent } from './schedule/schedule.component';
import { SendBackInitiatorEditComponent } from './send-back-initiator-edit/send-back-initiator-edit.component';
import { BrowserUtils } from '@azure/msal-browser/dist/utils/BrowserUtils';
import { DocViewerComponent } from './component/doc-viewer/doc-viewer.component';
import { TransactionDetailsComponent } from './component/transaction-details/transaction-details.component';

const routes: Routes = [
  { path: '', component:LoginComponent, pathMatch: 'full'},
  { path: 'dashboard', component:DashboardComponent,canActivate:[MsalGuard]},
 // { path: '', component:DashboardComponent },
 //{ path: '', component:HomeComponent },
 //{  path: 'code',component: HomeComponent },
   {path:'settings', component:ConfigurationSettingsComponent,canActivate:[MsalGuard]},
   {path:'manage-settings', component:ManageConfigurationSettingsComponent,canActivate:[MsalGuard]},
   {path:'manage-settings/:id/:mode', component:ManageConfigurationSettingsComponent,canActivate:[MsalGuard]},
   {path:'transactions', component:TransactionsComponent,canActivate: [MsalGuard]},
   {path:'transactions-detail/:id/:type', component:TransactionDetailComponent,canActivate: [MsalGuard]},
   {path:'transactions-details/:id/:type', component:TransactionDetailsComponent,canActivate: [MsalGuard]},
   {path: 'manage-transactions', component: ManageTransactionsComponent,canActivate:[MsalGuard]},
   {path: 'my-approvals', component: ApprovalsComponent,canActivate:[MsalGuard]},
   {path: 'schedule', component: ScheduleComponent,canActivate:[MsalGuard]},
   {path: 'approval-history', component: ApprovalHistoryComponent,canActivate:[MsalGuard]},
   {path: 'send-back-initiator',component: SendBackInitiatorEditComponent,canActivate:[MsalGuard]},
   {path: 'doc-viewer/:url',component: DocViewerComponent,canActivate:[MsalGuard]},
];
const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    //useHash: true,
    //initialNavigation: !isIframe ? 'enabled' : 'disabled'
   
  }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
