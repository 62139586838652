import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { sortBy } from 'lodash-es';
import { AuthService } from 'src/app/core/auth.service';
import { TokenStorage } from 'src/app/core/token-storage';
import { SendBackInitiatorEditComponent } from 'src/app/send-back-initiator-edit/send-back-initiator-edit.component';
import { LoaderService } from 'src/app/service/loader.service';
import { RestService } from 'src/app/service/rest.service';
import { ScreenAccessService } from 'src/app/service/screen-access.service';
import { AlertDialogComponent } from '../Shared/alert-dialog/alert-dialog.component';
import { AwitingApprovalDialogComponent } from '../Shared/awiting-approval-dialog/awiting-approval-dialog.component';
import { YesNoAlertDialogComponent } from '../Shared/yes-no-alert-dialog/yes-no-alert-dialog.component';
import { EncryptDecryptService } from 'src/app/service/encrypt-decrypt.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.css']
})
export class TransactionDetailsComponent implements OnInit {
  groups:any;
  transactions: Array<any> = [];
  data: any={};
  transactionId: string = ''
  type:string='';
  configActivityList: Array<any> = [];
  groupIds:any=[];
  isMyApproval:boolean=false;
  requestedDate:any;
  constructor(
    private encryptDecryptService:EncryptDecryptService,
    private dialog: MatDialog,
    private router:Router, 
    private token: TokenStorage,
    private restservice: RestService,
    public MsalauthService: MsalService, 
    private restService: RestService, 
    private loader: LoaderService, 
    public screenAccessService: ScreenAccessService,
    private _ativeRouter: ActivatedRoute, public authservice:AuthService,public datepipe: DatePipe ) {
    this._ativeRouter.params.subscribe(params => {
      this.transactionId = params['id'];
      this.type = params['type'];
    });
  }

  ngOnInit(): void {
    this.getMembers();
  }
    //Method to get config list
    getConfigList() {
      let configparams={
        SearchText: '',
        PageNo:0,
        PageSize:1000
      }
      this.restService.postData(configparams ,'api/ActivityConfig/ActivityConfigFetchList')
      .subscribe(
        (data) => {
          if(data.rcode==1) {
            if(data.rObj.ActivityConfig!=null){
              this.configActivityList=data.rObj.ActivityConfig
            }
           } 
        },
        (err) => {
          console.log(err);
          if (err.status === 401 || err.status === 403 || err.status === 0) {
            localStorage.clear();
            this.router.navigate([''])
            .then(() => {
              window.location.reload();
            });
          }
        }
      );
      this.getTransactions();
    }
  getTransactions() {
    this.loader.show()
    let params =
    { "TransactionID":this.transactionId } 

    this.restService.postData(params, '/api/Transaction/TransactionFetch').subscribe((res) => {
      if (res.rcode == 1) {
        this.data = res.rObj.Transaction;
        //this.requestedDate=formatDate(this.data.CreatedDate, 'dd/MM/YYYY hh:mm', 'en_US')
       // this.requestedDate=this.datepipe.transform(this.data.CreatedDate,"dd/MM/YYYY hh:mm")
        //this.requestedDate=moment(this.data.CreatedDate).format('DD/MM/YYYY hh:mm:ss');
        //this.requestedDate=moment(new Date()).format("DD/MM/YYYY hh:mm A");
        //this.requestedDate=this.authservice.formatAMPM(this.data.CreatedDate)
        this.requestedDate= new DatePipe('en-Us').transform(this.data.CreatedDate, 'dd/MM/YYYY hh:mm a', 'GMT+1');
        for (let j = 0; j < this.data.ApprovalLevels.length; j++) {
          let _previousLevels = this.data.ApprovalLevels.filter((x: any) => x.StatusID != 1007 && x.Level < this.data.CurrentLevel && this.data.CurrentLevel != 1)


          if (_previousLevels.length <= 0 && this.data.CurrentLevel != 1 && (this.data.FinanceAmount >= this.data.ApprovalLevels[j].FinancialsLowerLimit && this.data.FinanceAmount <= this.data.ApprovalLevels[j].FinancialsUpperLimit)) {
            this.data.isCurrentLevelSendback = false;
          }
          else {
            this.data.isCurrentLevelSendback = true;
          }

          this.groupIds.forEach((element: any) => {
         let _currentLevelApprovalGroup=   this.data.ApprovalLevels.filter((x: { Level: any; })=>x.Level==this.data.CurrentLevel)
         _currentLevelApprovalGroup.forEach((item:any) => {
           item.ApprovalGroups.forEach((grp:any) => {
            if(element.id==grp.GroupID && [1001,1004].includes(this.data.StatusID)){
              this.isMyApproval=true;
                
            }
           });
         
         });
       
          });

        }

        this.data.isSendBackToInitiator=false;
        if(this.data.CategoryID==3 && this.data.CurrentLevel==1){
          this.data.isSendBackToInitiator=true;
        }else if(this.data.CategoryID!=3){
          let levelsBetweenThePriceLimit=this.data.ApprovalLevels.filter((al:any)=>{
            return al.FinancialsLowerLimit <= this.data.FinanceAmount && al.FinancialsUpperLimit >= this.data.FinanceAmount;
          });
          levelsBetweenThePriceLimit= sortBy(levelsBetweenThePriceLimit, [function(o) { return o.Level; }])
          if(this.data.CurrentLevel==levelsBetweenThePriceLimit[0].Level){
            this.data.isSendBackToInitiator=true;
          }
        }


        /* setTimeout(() => {
          let id = this.configActivityList.find(item => item.id == this.data.activityConfigID);
        //console.log(id)
        if(id) {
          this.data.workflowTitle = id.Title
        }
        }, 1000) */
        //let _data=null
        if (this.transactions!= null) {
         // _data = this.transactions.find(x => x.id == this.transactionId);
          //this.data=_data
          this.transactions = this.data.ApprovalLevels;
        }
       
      }
      this.loader.hide();
      console.log(this.data);
      
    }, (err) => {
      this.loader.hide();
      console.log(err);
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        localStorage.clear();
        this.router.navigate([''])
        .then(() => {
          window.location.reload();
        });
      }
    })
  }
  
  backToList(){
    if(this.type=='Transaction'){
      this.router.navigate(['/transactions'])
    }
    if(this.type=='Approval'){
      this.router.navigate(['/my-approvals'])
    }
    if(this.type=='Dashboard'){
      this.router.navigate(['/dashboard'])
    }
  }

  closeDialog(): void {
    //  this.dialogRef.close();
  }

  approveLevel(dataApproval: any, type: number) {
    this.loader.show()
    this.authservice.getMyGroups()
    .subscribe((data: any) => {
      this.groupIds = [];
     let _groupIds = data.value;
     _groupIds.forEach((element: any) => {
      if (element.securityEnabled) {
        this.groupIds.push(element);
      }
     });
    

      //Fetch Transaction pon Approval Action
      let params =
      { "TransactionID":this.transactionId } 
  
      this.restService.postData(params, '/api/Transaction/TransactionFetch').subscribe((res) => {
        if (res.rcode == 1) {
          this.data = res.rObj.Transaction;  
          for (let j = 0; j < this.data.ApprovalLevels.length; j++) {
       
  
            this.groupIds.forEach((element: any) => {
           let _currentLevelApprovalGroup=   this.data.ApprovalLevels.filter((x: { Level: any; })=>x.Level==this.data.CurrentLevel)
           _currentLevelApprovalGroup.forEach((item:any) => {
             item.ApprovalGroups.forEach((grp:any) => {
              if(element.id==grp.GroupID && this.data.StatusID==1001){
                this.isMyApproval=true;
                  
              }
             });
           
           });
         
            });
  
          }
  
          if(this.isMyApproval){
            this.loader.hide();
            const dialogRef = this.dialog.open(AwitingApprovalDialogComponent, {
              width: '60vw',
              height: '70vh',
              data: { dataObj: dataApproval, type: type },
              disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
              this.getTransactions()
            });
          }
         
          this.loader.hide();
        }
       
  
      }, (err) => {
        this.loader.hide();
        console.log(err);
        if (err.status === 401 || err.status === 403 || err.status === 0) {
          localStorage.clear();
          this.router.navigate([''])
          .then(() => {
            window.location.reload();
          });
        }
      })
    },
      (err) => {
        this.loader.hide();
        console.log(err);
    
      })



  }

  getMembers() {
    this.authservice.getMyGroups()
      .subscribe((data: any) => {
        this.groupIds = [];
      
        let _groupIds = data.value;
        _groupIds.forEach((element: any) => {
         if (element.securityEnabled) {
           this.groupIds.push(element);
         }
        });
        this.getTransactions();
      },
        (errVal) => {
          this.loader.hide();
          console.log(errVal);
          if (errVal.status == 401) {
            const headers = new HttpHeaders({
              'Authorization': 'Bearer ' + this.token.MSALget()
            });
            this.restservice.postData(null, '/api/Account/GetApplicationToken', headers).subscribe((data) => {
              this.token.saveAppToken(data.rObj.applicationTokenResponse.access_token);
              this.getMembers();
            }, (err) => {
              console.log(err);
              if (err.status === 401 || err.status === 403 || err.status === 0) {
                localStorage.clear();
                this.router.navigate([''])
                  .then(() => {
                    window.location.reload();
                  });
              }
            });
          }
        })
  }





  edit(){
  const dialogRef = this.dialog.open(SendBackInitiatorEditComponent, {
    width: '80vw',
    height: '90vh',
    disableClose: true,
    data:this.data
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getTransactions()
      });
    }

    cancelTransaction(transactionId:any) {
      const dialogRef = this.dialog.open(YesNoAlertDialogComponent, {
        data: `Are you sure you need to cancel Transaction ${transactionId}?`,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          let params =  { 
            "TransactionID": transactionId
          };
          this.loader.show();
          this.restService.postData(params, '/api/Transaction/TransactionCancelByAdmin').subscribe((res) => {
            this.loader.hide();
            if(res.rcode == 1) {
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                disableClose: true,
                data:{data: res.rmsg ,  type:"SUCCESS"} 
              });
              dialogRef.afterClosed().subscribe(result=>{
                if(result){
                  this.getTransactions();
                }
              });
            } else {
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                disableClose: true,
                data:{data: res.rmsg ,  type:"WARNING"} 
              });
              dialogRef.afterClosed().subscribe(result=>{
              });
            }
          }, (err) => {
            this.loader.hide();
            console.log(err);
            if (err.Status === 401 || err.Status === 403 || err.Status === 0) {
              localStorage.clear();
              this.router.navigate([''])
              .then(() => {
                window.location.reload();
              });
            }
          })
        }
      });
  
    }

    viewAttachment(attachment: any) {
      let encryptedFileName=this.encryptDecryptService.encryptData(attachment.FileName);
      let encryptedFileURL=this.encryptDecryptService.encryptData(attachment.FileURL);
       let queryParams = {
         fileName: encryptedFileName
       };
   
       let url = this.router.serializeUrl(
           this.router.createUrlTree(['doc-viewer', encryptedFileURL], { queryParams: queryParams })
       );
   
       window.open(url, '_blank');
   }
  
  
}


