import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  ivBase64 = "X1iZ2ZAQwpU3qx/a544irw==";
  aesKeyBase64 = "VIKFb5M6j+k5RyrwWyt67+AtBV4Ljued56nyn4VzZoE=";
  constructor() { }
  encryptData(data: any) {
    try {
      let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.aesKeyBase64).toString();
      return encryptedData;
    } catch (e) {
      console.log(e);
      return data;
    }
  }
  decryptData(data:any) {
    try {
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data, this.aesKeyBase64);
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
      } else {
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  decryption(encryptedData: any) {
    if (!encryptedData) {
      return '';
    }

    // Base64-decode the IV and AES key
    const ivBytes = CryptoJS.enc.Base64.parse(this.ivBase64);
    const aesKey = CryptoJS.enc.Base64.parse(this.aesKeyBase64);

    // Decrypt using AES CBC mode
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      aesKey,
      { iv: ivBytes }
    );

    // Convert the decrypted WordArray to a string
    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}

