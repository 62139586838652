<div class="col-sm-12 col-md-12">
    <div class="col-sm-12 col-md-12 text-sm-left text-md-right">
        <a class=" btn btn-Action mt-5 mb-3" (click)="backToList()"> Back to My {{type}}</a>
    </div>
    <div class="text-center font-weight-bold h5">Transaction Details - ({{data.id}})
    </div>
    <div class="row">
        <div class="col-3">&nbsp;</div>
        <div class="top-div col-sm-12 col-md-6 row transaction_details">
            <div  class="col-md-12">
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Transaction Title</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.Title}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Transaction Description</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.Description}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Requested By</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.CreatedUserName}} ({{data.CreatedUser}})</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Requested On</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{authservice.formatAMPM(data.CreatedDate) }}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Business Unit</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.BusinessUnit}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Workflow Title</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.ActivityConfigTitle}}</span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Category</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.Category}}</span>
                    </div>
                </div>
               
                <div class="col-12 row my-1" *ngIf="data.Category!='Non Financial'">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span> Amount</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data.FinanceAmount}}</span>
                    </div>
                </div>

                <div class="col-12 row my-1" *ngIf="data.Category!='Non Financial' && data.Category!='Other Financial'">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span> Budgeted Amount</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span class="text-dark content_font">{{data?.BudgetedAmount||0}}</span>
                    </div>
                </div>

                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Status</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left">
                        <span
                            [ngClass]="{
                'rejected':data.Status === 'Rejected',
                'accepted': data.Status === 'Approved',
                'AwaitingDecision': data.Status === 'Awaiting Decision' || data.Status ===  'Awaiting Approval'}">{{data.Status}}
            </span>
                    </div>
                </div>
                <div class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right">
                        <span>Attachments</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left row pl-4">
                        <div *ngFor="let attachment of data?.RequiredDocuments">
                            <a class="pointer btn btn-info small m-1 btn_font" (click)="viewAttachment(attachment)"
                                target="_blank" title="Click to open"><i
                                    class="fa fa-eye mr-2"></i>{{attachment?.FileName}}</a>
                        </div>

                        <div *ngIf="data?.RequiredDocuments?.length==0">
                            <span class="pl-4">-</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="type == 'Approval' && isMyApproval" class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right mt-3">
                        <span>Action</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left row pl-4 pr-0">
                        <span class=" mx-1 tabData text-center">
                            <span *ngIf="data.StatusID!=1002 && data.StatusID!=1003 && data.StatusID!=1006"
                                class="icon-25 icon-approve_green mr-1 pointer" title="Approve"
                                (click)="approveLevel(data,1002)"></span>
                            <span *ngIf="data.StatusID==1002 || data.StatusID==1003 || data.StatusID==1006"
                                class="icon-25 icon-approve_green mr-1 pointer-ban" title="Approve not allowed"></span>
                        </span>
                        <span class=" mx-1 tabData text-center">
                            <span *ngIf="data.StatusID!=1002 && data.StatusID!=1003 && data.StatusID!=1006"
                                class="icon-25 icon-reject_red mr-1 pointer" title="Reject"
                                (click)="approveLevel(data,1003)"></span>
                            <span *ngIf="data.StatusID==1002 || data.StatusID==1003 || data.StatusID==1006"
                                class="icon-25 icon-reject_red mr-1 pointer-ban" title="Reject not allowed"></span>
                        </span>
                        <span class=" mx-1 tabData text-center">
                            <span *ngIf="data.StatusID!=1002 && data.StatusID!=1003 &&  data.StatusID!=1006"
                                class="icon-25 icon-hold_orange mr-1 pointer" title="On Hold"
                                (click)="approveLevel(data,1004)"></span>
                            <span *ngIf="data.StatusID ==1002 || data.StatusID ==1003 ||  data.StatusID==1006 "
                                class="icon-25 icon-hold_orange mr-1 pointer-ban" title="On Hold not allowed"></span>
                        </span>
                        <span class=" mx-1 tabData text-center">
                            <!-- <span *ngIf="transactions.length >= 1 && data.currentLevel >= 1 && (data.StatusID!=1002 && data.StatusID!=1003 && data.StatusID!=1006 && data.StatusID==1000)" class="icon-25 icon-sendback_black mr-1 pointer" title="Send back to Previous level" (click)="approveLevel(data,1003)"></span>
              <span *ngIf="(transactions.length==1) || (data.StatusID!=1002 || data.StatusID!=1003 || data.StatusID!=1006) || (data.currentLevel == 1)" class="icon-25 icon-sendback_black mr-1 pointer" title="Send back to Initiator" (click)="approveLevel(data,1005)" ></span> -->

                            <span
                                *ngIf="!data.isSendBackToInitiator  && data.isCurrentLevelSendback"
                                class="icon-25 icon-sendback_black mr-1 pointer" title="Send back to Previous level"
                                (click)="approveLevel(data,1005)"></span>
                            <span
                                *ngIf="data.isSendBackToInitiator || !data.isCurrentLevelSendback"
                                class="icon-25 icon-sendback_black mr-1 pointer" title="Send back to Initiator"
                                (click)="approveLevel(data,1008)"></span>


                        </span>
                    </div>
                </div>
                <div *ngIf="type == 'Dashboard' && screenAccessService.screenAccess.isAdmin" class="col-12 row my-1">
                    <div class="col-sm-12 col-md-6 text-sm-left text-md-right mt-3">
                        <span>Action</span>
                    </div>
                    <div class="col-sm-12 col-md-6 text-left row pl-4 pr-0">
                        <i *ngIf="data.StatusID == '1001' && screenAccessService.screenAccess.isAdmin" class="fa fa-times btn btn-cancel m-2"
                        (click)="cancelTransaction(data.id)" title="Cancel"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <i *ngIf="data.StatusID ==1008" class="fa fa-pencil-square-o fa-2x btn btn-edit  m-2 w-100 text-center"
                    title="Click here to Edit" aria-hidden="true" (click)="edit()"></i>
            </div>
        </div>
    </div>


    <div class="col-md-12">
        <h2 class="font-weight-bold top-div mt-3 mb-1">Approval Group Details</h2>
        <table class="table tabDesign table-hover table-responsive-sm table-responsive-md mt-2"
            *ngIf="transactions.length > 0">
            <tr class="col-sm-12 col-md-12">
                <th class="mx-1 tabhead" id="level">Level</th>
                <th class="mx-1 tabhead">Group</th>
                <th class="mx-1 tabhead">Comments</th>
                <th class="mx-1 tabhead">Attachments</th>
                <th class="mx-1 tabhead">Action By</th>
                <th class="mx-1 tabhead">Action For</th>
                <th class="mx-1 tabhead">Timestamp</th>
                <th class="mx-1 tabhead">Status</th>


            </tr>
            <tbody class="tabBody">
                <tr *ngFor="let transaction of transactions;let i=index;let last=last">
                    <td class=" mx-1 tabData">{{transaction.Level}} ({{transaction.LevelLabel}} ) </td>
                    <td class=" mx-1 tabData text-center">
                <tr *ngFor="let group of transaction.ApprovalGroups;let j=index">
                    <td rowspan="transaction.ApprovalGroups.length" class="mx-1 innerData text-center">{{group.Group}}
                    </td>
                </tr>
                </td>
                <td class=" mx-1 tabData description">
                    <span *ngIf="transaction.Comments!=undefined">{{transaction.Comments}}</span>
                    <div class="text-center" *ngIf="transaction.Comments==undefined || transaction.Comments==''">&ndash;
                    </div>
                </td>
                <td class=" mx-1 tabData text-center">
                    <div *ngIf="transaction.ApprovalDocuments!=undefined">
                        <tr *ngFor="let attachments of transaction.ApprovalDocuments;let j=index">
                            <td rowspan="transaction.ApprovalDocuments.length" class="mx-1 innerData text-center">
                                <a class="pointer" href="{{attachments.FileURL}}"
                                    target="_blank">{{attachments.FileName}}</a>
                            </td>
                        </tr>
                    </div>
                    <div *ngIf="transaction.ApprovalDocuments==undefined || transaction.ApprovalDocuments.length == 0">
                        &ndash;</div>

                </td>
                <td class=" mx-1 tabData text-center"
                    *ngIf="transaction.ApprovedUser != '' && transaction.ApprovedUser != undefined">
                    {{transaction.ApprovedUserName}} ({{transaction.ApprovedUser}})</td>
                <td class=" mx-1 tabData text-center">
                    {{transaction.isApprovedAsDelegateUser?transaction.delegateUser:'-'}}
                </td>
                <td class=" mx-1 tabData text-center"
                    *ngIf="transaction.ApprovedUser == '' || transaction.ApprovedUser == undefined">&ndash;</td>
                <td class=" mx-1 tabData text-center"
                    *ngIf="transaction.ApprovedDateTime != '' && transaction.ApprovedDateTime != undefined">
                    {{authservice.formatAMPM(transaction.ApprovedDateTime)}}</td>
                <td class=" mx-1 tabData text-center"
                    *ngIf="transaction.ApprovedDateTime == '' || transaction.ApprovedDateTime == undefined">&ndash;</td>
                <td class=" mx-1 tabData text-center" [ngClass]="{ 
                'AwaitingDecision': transaction.StatusID == 1001,
                'NextApprover': transaction.StatusID == 1009,
                'FinalApprover': transaction.StatusID == 1010,
                  'accepted': transaction.StatusID == 1002,
                    'rejected': transaction.StatusID == 1003,
                    'onHold': transaction.StatusID == 1004,
                    'sentBack': transaction.StatusID == 1005,
                    'cancelled': transaction.StatusID == 1006,
                    'notapplicable': transaction.StatusID == 1007,
                    'SentBackToInitiator': transaction.StatusID == 1008}">
                 
                    <span>
                        {{transaction.Status}}
                    </span>  

                 
                </td>

                 
                </tr>
                <tr *ngIf="transactions.length==0">
                    <td class=" mx-1 tabData" colspan="5">No record found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>